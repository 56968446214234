import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError, OperatorFunction, EMPTY } from 'rxjs';
import { filter, distinctUntilChanged, skip, catchError } from 'rxjs/operators';
import { Platform, ToastController, LoadingController, AlertController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Router, NavigationStart } from '@angular/router';
import { AlertButton } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  readonly LARGE_SCREEN_WIDTH_THRESHOLD = 992;

  readonly theme$: Observable<Theme>;
  private themeSubject: BehaviorSubject<Theme>;

  readonly prefersDark$: Observable<boolean>;
  private prefersDarkSubject: BehaviorSubject<boolean>;

  readonly sideMenuToggle$: Observable<SideMenuToggle>;
  private sideMenuToggleSubject: Subject<SideMenuToggle>;

  readonly isLargeScreen$: Observable<boolean>;
  private isLargeScreenSubject: BehaviorSubject<boolean>;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private router: Router,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private alertController: AlertController
  ) {

    const isLargeScreen = this.isLargeScreen();

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.initTheme();

    this.sideMenuToggleSubject = new Subject();
    this.sideMenuToggle$ = this.sideMenuToggleSubject.asObservable();

    this.isLargeScreenSubject = new BehaviorSubject(isLargeScreen);

    this.isLargeScreen$ = this.isLargeScreenSubject.asObservable()
      .pipe(distinctUntilChanged());

    this.prefersDark$ = this.prefersDarkSubject.asObservable()
      .pipe(distinctUntilChanged());

    this.theme$ = this.themeSubject.asObservable()
      .pipe(distinctUntilChanged());

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        skip(1)
      )
      .subscribe(event => {
        if (!this.isLargeScreen()) {
          this.closeSideMenu();
        }
      });
  }

  async presentConfirmAlert(header: string, message: string, button: AlertButton) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        button
      ]
    });

    await alert.present();
  }

  async presentToast(message: string,
                     color?: string,
                     duration: number = 4000,
                     showCloseButton: boolean = false): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create({
      message,
      duration,
      ...(color ? { color } : {}),
      buttons: (showCloseButton ? [{
        icon: 'close-outline',
        role: 'cancel'
      }] : [])
    });
    await toast.present();
    return toast;
  }

  async presentErrorToast(message: string) {
    this.presentToast(message, 'danger', 6000, true);
  }

  async withLoading<T>(message: string, handler: () => Promise<T>): Promise<T> {
    const loading = await this.presentLoading(message);
    return handler().finally(() => loading.dismiss());
  }

  async presentLoading(message: string): Promise<HTMLIonLoadingElement> {
    const loading = await this.loadingController.create({ message });
    await loading.present();
    return loading;
  }

  private initTheme() {

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.prefersDarkSubject = new BehaviorSubject(prefersDark.matches);

    const theme = prefersDark.matches ? 'dark' : 'light';
    this.themeSubject = new BehaviorSubject(theme);
    this.setTheme(theme);

    try {
      prefersDark.addEventListener('change', (mediaQuery) => {
        this.prefersDarkSubject.next(mediaQuery.matches);
      });
    } catch (e1) {
      try {
        // tslint:disable-next-line: deprecation
        prefersDark.addListener((mediaQuery) => {
          this.prefersDarkSubject.next(mediaQuery.matches);
        });
      } catch (e2) {
        console.error(e2);
      }
    }
  }

  setTheme(theme: Theme) {
    this.themeSubject.next(theme);
    document.body.classList.toggle('dark', theme === 'dark');
  }

  getTheme(): Theme {
    return this.themeSubject.value;
  }

  toggleSideMenu() {
    this.sideMenuToggleSubject.next({ forceClose: false });
  }

  closeSideMenu() {
    this.sideMenuToggleSubject.next({ forceClose: true });
  }

  isLargeScreen() {
    const width = window.innerWidth;
    return width >= this.LARGE_SCREEN_WIDTH_THRESHOLD;
  }

  async onUnauthorizedAccess(redirect: boolean = true, cb?: () => Promise<void>) {

    if (redirect) {
      await this.router.navigate(['home']);
      this.presentUnauthorizedToast();
      return;
    }

    if (cb) {
      await cb();
    }
    this.presentKickedOutToast();
  }

  public async presentUnauthorizedToast(message: string = 'You are unauthorized to view this resource', duration: number = 4000) {
    const toast = await this.toastController.create({
      message,
      duration,
      color: 'danger'
    });
    await toast.present();
    return toast;
  }

  onScreenResize() {
    const isLargeScreen = this.isLargeScreen();
    this.isLargeScreenSubject.next(isLargeScreen);

    if (!isLargeScreen) {
      this.closeSideMenu();
    }
  }

  public handleFirestorePermissionError<T>(context: string, redirect: boolean = true, cb?: () => Promise<void>): OperatorFunction<T, T> {
    return catchError(error => {
      console.error(`[${context}] ${error.message}`);
      if (error.code === 'permission-denied') {
        this.onUnauthorizedAccess(redirect, cb);
        return EMPTY;
      }

      return throwError({
        message: error.message
      });
    });
  }

  private async presentKickedOutToast(duration: number = 6000) {
    const toast = await this.toastController.create({
      message: 'Your session has expired, please login again',
      duration
    });
    await toast.present();
    return toast;
  }
}

export type Theme = 'dark' | 'light';

export type SideMenuToggle = {
  forceClose: boolean;
};
