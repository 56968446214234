import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { AppService } from './app.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private userService: UserService,
    private appService: AppService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.startsWith(environment.apiUrl)) {
      request = this.attachFirebaseAuthToken(request);
    }

    return next.handle(request).pipe(
      catchError(res => {

        if (res.status === 400) {
          this.appService.onUnauthorizedAccess(false, () => this.userService.signOut());
        }

        return throwError(res.error);
      })
    );
  }

  attachFirebaseAuthToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.userService.getUserToken();

    if (!token) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
