import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { IonMenu } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService, UserClaims } from './user.service';
import { tap, filter, map } from 'rxjs/operators';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild(IonMenu)
  ionMenu: IonMenu;

  splitPanelEnabled = true;

  userPages: PageMenuItem[] = [
    {
      title: 'Home',
      url: '/home',
      iconPrefix: 'home'
    },
    {
      title: 'Portfolio',
      url: '/evaluations',
      iconPrefix: 'analytics'
    },
    {
      title: 'Resources',
      url: '/resources',
      iconPrefix: 'book'
    }
  ];

  adminPages: PageMenuItem[] = [
    {
      title: 'Admin Control Panel',
      url: '/admin',
      iconPrefix: 'shield'
    }
  ];

  currentPageUrl$: Observable<string>;
  isLoggedIn$: Observable<boolean>;
  claims$: Observable<UserClaims>;
  isLargeScreen$: Observable<boolean>;

  constructor(
    private router: Router,
    private userService: UserService,
    private appService: AppService
  ) {

    this.appService.sideMenuToggle$
      .subscribe(toggle => this.toggleSideMenu(toggle.forceClose));

    this.claims$ = this.userService.claims$;

    this.isLargeScreen$ = this.appService.isLargeScreen$;

    this.isLoggedIn$ = this.userService.isLoggedIn$.pipe(
      tap(isLoggedIn => this.onLoginChange(isLoggedIn))
    );

    this.currentPageUrl$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => `/${event.urlAfterRedirects.slice(1).split('/', 1)[0]}`)
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.appService.onScreenResize();
  }

  ngOnInit() { }

  private onLoginChange(isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.toggleSideMenu(true);
      return;
    }

    const isLargeScreen = this.appService.isLargeScreen();
    if (isLargeScreen && !this.splitPanelEnabled) {
      this.toggleSideMenu();
    }
  }

  async toggleSideMenu(forceClose = false) {

    const isLargeScreen = this.appService.isLargeScreen();
    const isOpen = isLargeScreen ? this.splitPanelEnabled : await this.ionMenu.isOpen();

    if (forceClose && !isOpen) {
      return;
    }

    if (!isLargeScreen) {
      this.ionMenu.toggle();
      return;
    }

    this.splitPanelEnabled = !this.splitPanelEnabled;
  }

  async signOut() {
    await this.userService.signOut();
  }
}

type PageMenuItem = {
  title: string;
  url: string;
  iconPrefix: string;
};
