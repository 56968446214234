import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { FinancialTableData } from 'src/app/evaluation.service';

@Component({
  selector: 'app-financial-chart',
  templateUrl: './financial-chart.component.html',
  styleUrls: ['./financial-chart.component.scss'],
})
export class FinancialChartComponent implements OnInit, OnChanges {

  @Input()
  data: FinancialTableData;

  @Input()
  config: FinancialChartConfiguration;

  chart: Chart;

  growthRates: FinancialChartGrowthRate[];

  constructor() { }

  ngOnInit() {
    this.chart = this.buildChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && !changes.config.isFirstChange()) {
      this.updateChart(this.chart, this.data, this.config);
      this.growthRates = this.buildGrowthRates(this.data, [3, 5, 10], this.config);
    }
  }

  calcGrowthRate(data: FinancialTableData, n: number, dataKey: string) {
    const years = Object.keys(data).sort();
    const latest = data[years[years.length - 1]][dataKey];
    return Math.pow(latest / data[years[years.length - n]][dataKey], (1 / (n - 1))) - 1;
  }

  buildGrowthRates(data: FinancialTableData, n: [number, number, number], config: FinancialChartConfiguration): FinancialChartGrowthRate[] {
    return n.map(x => ({
      rate: this.calcGrowthRate(data, x, config.dataKey),
      label: `${x} Year Growth Rate`
    }));
  }

  buildChart(): Chart {
    const style = window.getComputedStyle(document.body);
    const labelColor = style.getPropertyValue('--ion-color-dark');
    const pointColor = style.getPropertyValue('--ion-color-primary');

    const context = document.getElementsByTagName('canvas')[0] as HTMLCanvasElement;
    return new Chart(context, {
      type: 'line',
      data: {
        labels: [],
        datasets: [{
          data: [],
          pointBackgroundColor: pointColor,
          borderColor: `${pointColor}1A`,
          backgroundColor: `${pointColor}1A`
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: 'Poppins',
              fontColor: labelColor
            },
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: 'Poppins',
              fontColor: labelColor
            },
          }]
        }
      }
    });
  }

  updateChart(chart: Chart, data: FinancialTableData, config: FinancialChartConfiguration) {
    const years = Object.keys(data).sort();

    chart.data.labels = config.formatLabel ? years.map(year => config.formatLabel(year)) : years;
    chart.data.datasets[0].data = years.map((year) => data[year][config.dataKey]);

    chart.options.scales.yAxes[0].scaleLabel.labelString = config.yAxisLabel;
    chart.options.scales.xAxes[0].scaleLabel.labelString = config.xAxisLabel ? config.xAxisLabel : 'Year';

    chart.update();
  }
}

export type FinancialChartConfiguration = {
  dataKey: string;
  yAxisLabel: string;
  xAxisLabel?: string;
  title: string;
  formatLabel?: (label: string) => string;
};

export type FinancialChartGrowthRate = {
  rate: number;
  label: string;
}
