import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-signed-number-note',
  templateUrl: './signed-number-note.component.html',
  styleUrls: ['./signed-number-note.component.scss'],
})
export class SignedNumberNoteComponent implements OnInit, OnChanges {

  @Input()
  value: number;

  @Input()
  percent = false;

  @Input()
  showSign = true;

  @Input()
  wrapInParenthesis = false;

  @Input()
  colored = true;

  color: string;

  sign = '';

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.color = this.resolveColor(this.value, this.colored);
      this.sign = this.showSign ? this.resolveSign(this.value) : '';
      this.value = Math.abs(this.value);
    }
  }

  resolveSign(value: number) {

    if (value > 0) {
      return '+';
    } else if (value < 0) {
      return '-';
    }

    return '';
  }

  resolveColor(value: number, colored: boolean) {
    if (value === 0 || !colored) {
      return 'medium';
    } else if (value < 0) {
      return 'danger';
    }

    return 'success';
  }

}
