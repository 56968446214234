import { Component, OnInit, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import tippy, { Instance as Tippy, SingleTarget } from 'tippy.js';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss'],
})
export class StatusIconComponent implements OnInit {

  @ViewChild('icon', { static: true, read: ElementRef })
  iconElement: ElementRef;

  @Input()
  config: StatusIconConfiguration;

  tippy: Tippy;

  constructor() { }

  ngOnInit() {
    this.tippy = tippy(this.iconElement.nativeElement as SingleTarget, {
      content: this.config.tooltip,
      showOnCreate: false,
      trigger: 'manual',
      placement: 'top-start',
      animation: 'shift-toward',
      maxWidth: 200,
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 16
            }
          }
        ]
      }
    });
  }

  @HostListener('mouseover')
  presentPopper() {
    this.tippy.show();
  }

  @HostListener('mouseleave')
  dismissPopper() {
    this.tippy.hide();
  }

}

export type StatusIconState = 'success' | 'danger' | 'warning' | 'medium';

export type StatusIconConfiguration = {
  state: StatusIconState;
  tooltip?: string;
};
