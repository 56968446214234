import { Component, OnInit, Input, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeInputComponent),
      multi: true
    }
  ]
})
export class RangeInputComponent implements OnInit, ControlValueAccessor {

  @Input()
  min: number;

  @Input()
  max: number;

  @Input()
  step = 1;

  @ViewChild('input', { static: false })
  inputElement: IonInput;

  isIntegral = false;

  inputMode = true;

  rangeScale: number;

  rangeMin: number;

  rangeMax: number;

  rangeStep: number;

  rangeValueControl: FormControl = new FormControl(0);

  inputValueControl: FormControl = new FormControl(0);

  propagateChange: (_: number) => void;

  constructor() { }

  ngOnInit() {

    this.isIntegral = Number.isInteger(this.step);

    if (!this.isIntegral) {
      this.rangeScale = 1 / this.step;
      this.rangeMax = this.max * this.rangeScale;
      this.rangeMin = this.min * this.rangeScale;
      this.rangeStep = this.step * this.rangeScale;
    }

    this.rangeValueControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.inputValueControl.setValue(value / this.rangeScale, { emitEvent: false });
      this.propagateChange(this.inputValueControl.value);
    });

    this.inputValueControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (value == null || isNaN(value)) {
        value = 0;
        this.inputValueControl.setValue(value);
        return;
      }

      this.rangeValueControl.setValue(value * this.rangeScale, { emitEvent: false });
      this.propagateChange(value);
    });

    // initialise to false to avoid flickering on toggleInputMode
    setTimeout(() => this.inputMode = false);
  }

  writeValue(value: number) {
    if (isNaN(value)) {
      return;
    }

    this.inputValueControl.setValue(value, { emitEvent: false });
    this.rangeValueControl.setValue(value * this.rangeScale, { emitEvent: false });
  }

  registerOnChange(fn: (_: number) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: number) => void) {
    // this.propagateChange = fn;
  }

  toggleInputMode(inputMode?: boolean) {
    if (inputMode === undefined) {
      inputMode = !this.inputMode;
    }

    this.inputMode = inputMode;

    if (this.inputMode) {
      setTimeout(() => {
        this.inputElement.setFocus();
      });
    }
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.rangeValueControl.disable();
      this.inputValueControl.disable();
    } else {
      this.rangeValueControl.enable();
      this.inputValueControl.enable();
    }
  }

}
