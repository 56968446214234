import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FinancialTableData } from 'src/app/evaluation.service';

@Component({
  selector: 'app-financial-table',
  templateUrl: './financial-table.component.html',
  styleUrls: ['./financial-table.component.scss'],
})
export class FinancialTableComponent implements OnInit {

  @Input()
  config: FinancialTableConfiguration;

  @Output()
  selected: EventEmitter<FinancialTableRow> = new EventEmitter();

  selectedRowIndex: number;

  constructor() { }

  ngOnInit() {}

  selectRow(row: FinancialTableRow, i: number) {

    if (this.selectedRowIndex === i) {
      return;
    }

    this.selected.emit(row);
    this.selectedRowIndex = i;
  }

}

export type FinancialTableConfiguration = {
  rows: FinancialTableRow[];
  columns: { key: string, label?: string }[];
  data: FinancialTableData;
};

export type FinancialTableRow = {
  key: string;
  label: string;
  postFix?: string;
};
