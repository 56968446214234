import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { FinancialTableComponent } from './financial-table/financial-table.component';
import { StatusIconComponent } from './status-icon/status-icon.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FinancialChartComponent } from './financial-chart/financial-chart.component';
import { RangeInputComponent } from './range-input/range-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SignedNumberNoteComponent } from './signed-number-note/signed-number-note.component';
import { UserBadgeComponent } from './user-badge/user-badge.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

@NgModule({
  declarations: [
    PlaceholderComponent,
    FinancialTableComponent,
    StatusIconComponent,
    FinancialChartComponent,
    RangeInputComponent,
    SignedNumberNoteComponent,
    UserBadgeComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    IonicModule,
    NgxTippyModule
  ],
  exports: [
    PlaceholderComponent,
    FinancialTableComponent,
    StatusIconComponent,
    FinancialChartComponent,
    RangeInputComponent,
    SignedNumberNoteComponent,
    UserBadgeComponent
  ]
})
export class ComponentsModule { }
